@import "helpers/variables";
@import "helpers/responsive";

.kendo-tab-wrapper {
    --kendo-tab-gap: 24px;

    &:has(.tab-view-all-area),
    &:has(.owl-nav) {
        .k-tabstrip-items-wrapper {
            @include mq(phone, min) {
                padding-right: 100px;
            }
        }
    }

    .k-tabstrip {
        &:has(.k-tabstrip-content:not(:empty)) {
            gap: var(--kendo-tab-gap);
        }
    }

    .tab-view-all-area {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: calc(-1 * var(--kendo-tab-gap) - 22px);

        @include mq(phone) {
            justify-content: flex-start;
            position: static;
            margin-top: 18px;

            .k-button-link {
                padding: 6px;
                margin: 0 -6px;
            }

        }
    }
}

.k-tabstrip {

    .k-tabstrip-content {
        &:focus {
            outline: none;
        }
    }

    .k-tabstrip-items-wrapper,
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        border-color: transparent;
    }


    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        padding: 0;
        overflow: visible;
    }

    .k-tabstrip-items {
        flex-wrap: nowrap;
        gap: 12px;
        @include allow-scroll;

        @include mq(tablet) {
            @include scroll-gap-x;
        }

        .k-item {
            --tab-active-color: #ED7D31;
            --border-color: #ECE2DF;
            background-color: #FDFBFB;
            color: var(--color-black-subtle);
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            border-radius: 38px !important;
            border: 1px solid var(--border-color) !important;
            margin: 0 !important;
            box-shadow: none !important;
            transition: all .4s;

            &:active,
            &.k-active,
            &.k-selected {
                --border-color: var(--tab-active-color);
                border-radius: 46px;
                background: rgba(232, 108, 22, 0.10);
            }

            &:hover {
                color: var(--tab-active-color);
            }
        }

        .k-link {
            padding: 10px 16px;
        }
    }


}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
    border-bottom-color: var(--tab-active-color) !important;
}
